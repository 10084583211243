import { useAuthStore } from "@/store/auth"
import { PublicConnectorDetails } from "@/store/newPublicConnector"
import { AnalyticsResponseType, TransactionsAnalyticsResponseData } from "@/types/Analytics"
import { ConfigType, ConnectorType, DataConnectorsType } from "@/types/DataConnectorsType"
import { ResponseType } from "@/types/LoginResponseType"
import { StatisticType } from "@/types/StatisticType"
import { TemplatesType } from "@/types/TemplatesType"
import { UserType } from "@/types/UserType"
import { notification } from "antd"
import ky, { NormalizedOptions } from "ky"
import { SERVICE_PATHS, SERVICE_PORTS } from '../constants'

const BASE_URL = import.meta.env.WEBSITE_API_BASE_URL ?? `http://localhost:${SERVICE_PORTS.API}`

const errorNotification = async (_request: Request, _options: NormalizedOptions, response: Response) => {
  if (!response.ok) {
    const errorMessage = await response.text()
    notification.error({ placement: "topRight", message: `Error: ${errorMessage}'` })
  }
}

const api = ky.create({
  prefixUrl: BASE_URL,
  timeout: 20000,
  hooks: {
    beforeRequest: [
      async request => {
        const token = localStorage.getItem("token")!
        if (token) {
          // Only set Content-Type for non-FormData requests
          if (!(request.body instanceof FormData)) {
            request.headers.set("Content-Type", "application/json")
          }
          request.headers.set("Authorization", `Bearer ${token}`)
        }
      },
    ],
    afterResponse: [
      errorNotification,
      async (request, options, response) => {
        if (response.status === 401) {
          await useAuthStore.getState().refresh()

          const newToken = localStorage.getItem("token")!
          if (newToken) {
            // Only set Content-Type for non-FormData requests
            if (!(request.body instanceof FormData)) {
              request.headers.set("Content-Type", "application/json")
            }
            request.headers.set("Authorization", `Bearer ${newToken}`)
            return ky(request)
          }
        }
        return response
      },
    ],
  },
})

// API Endpoints - Remove leading slashes since we're using prefixUrl
const AUTH_PATH = `${SERVICE_PATHS.USERS.substring(1)}/auth`
const ME_PATH = `${SERVICE_PATHS.USERS.substring(1)}/me`
const ALL_USERS_PATH = SERVICE_PATHS.USERS.substring(1)
const CONFIRM_USER = `${SERVICE_PATHS.USERS.substring(1)}/confirm`

const REGISTRATION_PATH = `${SERVICE_PATHS.API.substring(1)}/registration`
const REFRESH_PATH = `${SERVICE_PATHS.API.substring(1)}/refresh`
const RESET_PASSWORD_PATH = `${SERVICE_PATHS.USERS.substring(1)}/reset-password`
const UPDATE_PASSWORD_PATH = `${SERVICE_PATHS.USERS.substring(1)}/update-password`

const ALL_CONNECTORS_PATH = SERVICE_PATHS.CONNECTORS.substring(1)
const CREATE_CONNECTOR_PATH = `${SERVICE_PATHS.CONNECTORS.substring(1)}/`
const UPDATE_CONNECTOR_PATH = `${SERVICE_PATHS.CONNECTORS.substring(1)}/`
const DELETE_CONNECTOR = `${SERVICE_PATHS.CONNECTORS.substring(1)}/`
const VALIDATE_CONFIG = `${SERVICE_PATHS.CONNECTORS.substring(1)}/validateConfig`
const VALIDATE_DATA_MAPPING = `${SERVICE_PATHS.CONNECTORS.substring(1)}/validateDataMapping`
const PAUSE_CONNECTOR = `${SERVICE_PATHS.CONNECTORS.substring(1)}/pause`
const RESUME_CONNECTOR = `${SERVICE_PATHS.CONNECTORS.substring(1)}/resume`

const ALL_CONFIGS_PATH = SERVICE_PATHS.CONFIGS.substring(1)

const GET_DASHBOARD_ANALYTICS = `${SERVICE_PATHS.API.substring(1)}/analytics/dashboard`
const GET_STATISTIC = `${SERVICE_PATHS.API.substring(1)}/analytics/statistic`
const GET_TRANSACTIONS = `${SERVICE_PATHS.API.substring(1)}/analytics/transactions`

const GET_ALL_PUBLIC_CONNECTORS = `${SERVICE_PATHS.API.substring(1)}/templates`
const CREATE_PUBLIC_CONNECTOR = `${SERVICE_PATHS.API.substring(1)}/templates`
const UPDATE_PUBLIC_CONNECTOR = `${SERVICE_PATHS.API.substring(1)}/templates/`
const DELETE_PUBLIC_CONNECTOR = `${SERVICE_PATHS.API.substring(1)}/templates/`

export async function checkAuth(token: string | null) {
  try {
    console.log('Attempting auth check with token:', token ? `${token.substring(0, 10)}...` : 'null');
    
    if (!token) {
      console.warn('No token provided for auth check');
      throw new Error('No token provided');
    }

    // Use direct ky call to auth service instead of the api client
    console.log('Making request to auth service:', `http://localhost:${SERVICE_PORTS.AUTH}/api/auth/decode`);
    console.log('Request payload:', { token: token.substring(0, 10) + '...' });
    
    const response = await ky.post(`http://localhost:${SERVICE_PORTS.AUTH}/api/auth/decode`, {
      headers: {
        "Content-Type": "application/json",
      },
      json: { token },
      timeout: 8000,
      retry: {
        limit: 2,
        methods: ['POST'],
        statusCodes: [408, 429, 500, 502, 503, 504],
      }
    }).json<{ user: UserType }>()

    console.log('Auth check successful, user data received:', response);
    return response.user
  } catch (error) {
    console.error('Auth check failed:', error);
    if (error instanceof Error) {
      console.error('Error details:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
      notification.error({ 
        placement: "topRight", 
        message: "Auth check failed", 
        description: error.message === 'Request timed out' ? 'Connection to server timed out. Please check your network.' : error.message
      });
    }
    throw error
  }
}

export async function confirmUser(token: string): Promise<ResponseType> {
  try {
    return api.post(CONFIRM_USER, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    }).json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `User confirmation failed: ${error.message}` })
    throw error
  }
}

export async function login(email: string, password: string): Promise<ResponseType> {
  try {
    return api.post(AUTH_PATH, {
      headers: {
        "Content-Type": "application/json",
      },
      json: { email, password },
      timeout: 5000,
    }).json()
  } catch (error) {
    if (error instanceof Error) notification.error({ placement: "topRight", message: `Login failed: ${error.message}` })
    throw error
  }
}

export async function registration(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  workspaceName?: string,
  companyName?: string
) {
  try {
    return ky(`${BASE_URL}${REGISTRATION_PATH}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, firstName, lastName, workspaceName, companyName }),
      //hooks: { afterResponse: [errorNotification] },
    }).json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Registration failed: ${error.message}` })
    throw error
  }
}

export async function handleRefreshToken(refreshToken: string) {
  try {
    return api
      .post(REFRESH_PATH, {
        headers: {
          "Content-Type": "application/json",
        },
        json: { refreshToken },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Refresh token failed: ${error.message}` })
    throw error
  }
}

export async function resetPassword(email: string) {
  try {
    return ky(`${BASE_URL}${RESET_PASSWORD_PATH}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
      //hooks: { afterResponse: [errorNotification] },
    }).json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Password reset failed: ${error.message}` })
    throw error
  }
}

export async function updatePassword(password: string, token: string) {
  try {
    return ky(`${BASE_URL}${UPDATE_PASSWORD_PATH}`, {
      method: "POST",
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password: { new: password } }),
      //hooks: { afterResponse: [errorNotification] },
    }).json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Password update failed: ${error.message}` })
    throw error
  }
}

export async function getAllConnectors(token: string): Promise<DataConnectorsType[]> {
  try {
    // Decode the JWT token to get the userId
    const tokenParts = token.split('.');
    const payload = JSON.parse(atob(tokenParts[1]));
    const userId = payload.sub;

    // Use the user-specific endpoint with userId in query parameters
    const connectors = await api
      .get(`${ALL_CONNECTORS_PATH}/user`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        searchParams: {
          userId
        }
      })
      .json<any[]>()
    
    // Transform the raw connector data to the expected DataConnectorsType format
    return connectors.map(connector => {
      return {
        connector: {
          _id: connector.connector._id,
          name: connector.connector.name,
          details: connector.connector.details || "",
          status: connector.connector.status,
          integrationName: connector.connector.integrationName || "",
          budgetLimit: connector.connector.budgetLimit,
          computeSize: connector.connector.computeSize || "",
          configId: connector.connector.configId,
          createdAt: connector.connector.createdAt,
          updatedAt: connector.connector.updatedAt,
          encryptionOption: connector.connector.encryptionOption || "",
          supportPackage: connector.connector.supportPackage || "",
          storeLocally: connector.connector.storeLocally
        },
        config: connector.config || {
          _id: connector.connector.configId,
          connectorId: connector.connector._id,
          name: connector.connector.name,
          status: connector.connector.status
        }
      };
    });
  } catch (error) {
    if (error instanceof Error)
      notification.error({
        placement: "topRight",
        message: `Failed to fetch connectors: ${error.message}`,
      })
    console.error("Error fetching connectors:", error)
    return []
  }
}

export async function getConnector(id: string, token: string): Promise<DataConnectorsType> {
  try {
    return api
      .get(`${ALL_CONNECTORS_PATH}/${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get connector by id failed: ${error.message}` })
    throw error
  }
}

export async function createConnector(body: any, token: string): Promise<DataConnectorsType> {
  try {
    console.log('Creating connector with body keys:', Object.keys(body));
    
    // Check if body contains a media file
    if (body.media instanceof File || (body.media && body.media.data)) {
      console.log('Media detected, using FormData for upload');
      
      // Create FormData object for file upload
      const formData = new FormData();
      
      // Append all other fields first
      Object.entries(body).forEach(([key, value]) => {
        if (key !== 'media') {
          if (key === 'methods' && Array.isArray(value)) {
            console.log(`Appending methods as JSON string, count: ${value.length}`);
            formData.append('methods', JSON.stringify(value));
          } else if (value !== undefined && value !== null) {
            console.log(`Appending field: ${key}`);
            formData.append(key, typeof value === 'object' ? JSON.stringify(value) : String(value));
          }
        }
      });
      
      // Handle media last to avoid issues with multipart form data
      if (body.media instanceof File) {
        console.log('Media is a File object:', {
          name: body.media.name,
          type: body.media.type,
          size: `${(body.media.size / 1024).toFixed(2)} KB`
        });
        
        // Check file size before uploading
        if (body.media.size > 10 * 1024 * 1024) {
          throw new Error('File too large. Maximum size is 10MB.');
        }
        
        // Check file type
        if (!body.media.type.startsWith('image/') && 
            body.media.type !== 'application/pdf' && 
            body.media.type !== 'application/json') {
          throw new Error('Unsupported file type. Only images, PDFs, and JSON files are allowed.');
        }
        
        try {
          // Read the file as ArrayBuffer
          const arrayBuffer = await body.media.arrayBuffer();
          
          // Create a smaller file (max 1MB chunks)
          const MAX_CHUNK_SIZE = 1 * 1024 * 1024; // 1MB
          
          if (body.media.size <= MAX_CHUNK_SIZE) {
            // If file is already small enough, use it directly
            console.log('File is small enough, using directly');
            formData.append('media', new File([arrayBuffer], body.media.name, { 
              type: body.media.type,
              lastModified: body.media.lastModified
            }));
          } else {
            // For larger files, compress if it's an image
            if (body.media.type.startsWith('image/')) {
              console.log('Compressing image before upload');
              
              // Create a temporary image element to compress the image
              const img = document.createElement('img');
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              
              // Create a blob URL from the file
              const url = URL.createObjectURL(body.media);
              
              // Wait for the image to load
              await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
                img.src = url;
              });
              
              // Calculate new dimensions (max 1024px)
              const MAX_SIZE = 1024;
              let width = img.width;
              let height = img.height;
              
              if (width > height && width > MAX_SIZE) {
                height = Math.round((height * MAX_SIZE) / width);
                width = MAX_SIZE;
              } else if (height > MAX_SIZE) {
                width = Math.round((width * MAX_SIZE) / height);
                height = MAX_SIZE;
              }
              
              // Set canvas dimensions and draw the image
              canvas.width = width;
              canvas.height = height;
              ctx?.drawImage(img, 0, 0, width, height);
              
              // Clean up the blob URL
              URL.revokeObjectURL(url);
              
              // Convert canvas to blob with reduced quality
              const blob = await new Promise<Blob>((resolve) => {
                canvas.toBlob((b) => resolve(b as Blob), 'image/jpeg', 0.7);
              });
              
              console.log('Compressed image size:', `${(blob.size / 1024).toFixed(2)} KB`);
              
              // Create a new file from the blob
              const compressedFile = new File([blob], body.media.name.replace(/\.[^.]+$/, '.jpg'), { 
                type: 'image/jpeg',
                lastModified: body.media.lastModified
              });
              
              formData.append('media', compressedFile);
            } else {
              // For non-image files, use the original but warn about potential issues
              console.warn('Large non-image file detected, upload may fail:', body.media.type);
              formData.append('media', new File([arrayBuffer], body.media.name, { 
                type: body.media.type,
                lastModified: body.media.lastModified
              }));
            }
          }
        } catch (e) {
          console.error('Error processing file:', e);
          throw new Error('Error processing file: ' + (e instanceof Error ? e.message : String(e)));
        }
      } else if (body.media && body.media.data) {
        console.log('Media has data property, creating Blob');
        try {
          // Convert base64 to blob
          const byteCharacters = atob(body.media.data);
          const byteArrays = [];
          for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
          }
          const byteArray = new Uint8Array(byteArrays);
          const blob = new Blob([byteArray], { type: 'image/png' });
          
          // Check blob size
          if (blob.size > 10 * 1024 * 1024) {
            throw new Error('File too large. Maximum size is 10MB.');
          }
          
          const file = new File([blob], 'icon.png', { type: 'image/png' });
          console.log('Created File from data', { size: `${(file.size / 1024).toFixed(2)} KB`, type: file.type });
          formData.append('media', file);
        } catch (e) {
          console.error('Error converting media data to File:', e);
          throw new Error('Error processing image data: ' + (e instanceof Error ? e.message : String(e)));
        }
      }
      
      // Log all form data entries for debugging
      console.log('FormData entries:');
      for (const pair of (formData as any).entries()) {
        console.log(`${pair[0]}: ${typeof pair[1] === 'object' ? 'File or Object' : pair[1]}`);
      }
      
      // Use direct ky call instead of api to avoid the Content-Type header being set
      return await ky.post(`${BASE_URL}${CREATE_CONNECTOR_PATH}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          // Don't set Content-Type, let the browser set it for FormData
        },
        body: formData,
        timeout: 180000, // Increase timeout to 3 minutes for large files
        retry: {
          limit: 5,
          methods: ['POST'],
          statusCodes: [408, 413, 429, 500, 502, 503, 504],
          maxRetryAfter: 5000, // Maximum retry after time in milliseconds
        },
        hooks: {
          beforeRequest: [
            request => {
              console.log('Sending request to:', request.url);
            }
          ],
          afterResponse: [
            (request, options, response) => {
              console.log('Response status:', response.status);
              return response;
            }
          ]
        },
        throwHttpErrors: false // Don't throw HTTP errors automatically
      }).json();
    } else {
      // No media file, use regular JSON
      console.log('No media detected, using JSON');
      return api
        .post(CREATE_CONNECTOR_PATH, {
          headers: {
            "Authorization": token ? `Bearer ${token}` : undefined,
            "Content-Type": "application/json",
          },
          json: body,
          timeout: 5000, // 5 seconds timeout
        })
        .json();
    }
  } catch (error) {
    console.error('Error in createConnector:', error);
    
    // Check if the error has a response property (HTTP error)
    if (error && typeof error === 'object' && 'response' in error && error.response) {
      try {
        // Try to parse the error response as JSON
        const errorData = await (error.response as Response).json();
        console.error('Server error response:', errorData);
        
        // Use the server's error message if available
        if (errorData && errorData.message) {
          notification.error({ 
            placement: "topRight", 
            message: "Create connector failed", 
            description: errorData.message,
            duration: 8
          });
          throw new Error(errorData.message);
        }
      } catch (jsonError) {
        // If we can't parse the JSON, fall back to the status text
        console.error('Error parsing error response:', jsonError);
      }
    }
    
    if (error instanceof Error) {
      let errorMessage = `Create connector failed: ${error.message}`;
      
      // Handle specific error types
      if (error.message.includes('Multipart: Unexpected end of form')) {
        errorMessage = 'File upload was interrupted. Please try again with a smaller file or a more stable connection.';
      } else if (error.message.includes('File too large')) {
        errorMessage = 'File is too large. Maximum size is 10MB.';
      } else if (error.message.includes('Unsupported file type')) {
        errorMessage = 'Unsupported file type. Only images, PDFs, and JSON files are allowed.';
      } else if (error.message.includes('ValidationError')) {
        errorMessage = 'Validation failed. Please check your input data.';
      } else if (error.message.includes('timeout')) {
        errorMessage = 'Request timed out. Please try again with a smaller file or a more stable connection.';
      } else if (error.message.includes('NetworkError')) {
        errorMessage = 'Network error occurred. Please check your internet connection and try again.';
      }
      
      notification.error({ 
        placement: "topRight", 
        message: "Create connector failed", 
        description: errorMessage,
        duration: 8
      });
    }
    throw error;
  }
}

export async function updateConnector(
  body: Partial<ConnectorType>,
  id: string,
  token: string
): Promise<DataConnectorsType> {
  try {
    return api
      .patch(`${UPDATE_CONNECTOR_PATH}${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: body,
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Update connector failed: ${error.message}` })
    throw error
  }
}

export async function validateConfig(body: any, token: string) {
  try {
    return api
      .post(VALIDATE_CONFIG, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: body,
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Validate config failed: ${error.message}` })
    throw error
  }
}

export async function validateDataMapping(body: any, token: string) {
  try {
    return api
      .post(VALIDATE_DATA_MAPPING, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: { dataMapping: body },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Validate data mapping failed: ${error.message}` })
    throw error
  }
}

export async function getAllUsers(): Promise<UserType[]> {
  try {
    return api.get(ALL_USERS_PATH).json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get all users failed: ${error.message}` })
    throw error
  }
}

export async function removeConnector(id: string, token: string): Promise<ConnectorType> {
  try {
    return api
      .delete(`${DELETE_CONNECTOR}${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Remove connector failed: ${error.message}` })
    throw error
  }
}

export async function getConfigById(id: string, token: string): Promise<ConfigType> {
  try {
    return api
      .get(`${ALL_CONFIGS_PATH}/${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get config by id failed: ${error.message}` })
    throw error
  }
}

export async function pauseConnector(id: string, token: string): Promise<ConfigType> {
  try {
    return api
      .post(`${PAUSE_CONNECTOR}/${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Pause connector failed: ${error.message}` })
    throw error
  }
}

export async function resumeConnector(id: string, token: string): Promise<ConfigType> {
  return api
    .post(`${RESUME_CONNECTOR}/${id}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    })
    .json()
}

export async function getDashboardAnalytics(
  connectorId: string,
  startDate: string,
  endDate: string,
  interval: string,
  token: string,
  status?: string[]
): Promise<AnalyticsResponseType> {
  try {
    return api
      .post(`${GET_DASHBOARD_ANALYTICS}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: { connectorId, startDate, endDate, interval, statusCodes: status },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get dashboard analytics failed: ${error.message}` })
    throw error
  }
}

export async function getStatistic(
  connectorId: string,
  startDate: string,
  endDate: string,
  token: string
): Promise<StatisticType> {
  try {
    return api
      .post(`${GET_STATISTIC}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: { connectorId, startDate, endDate },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get statistic failed: ${error.message}` })
    throw error
  }
}

export async function getTransactionsAnalytics(
  connectorId: string,
  startDate: string,
  endDate: string,
  token: string
): Promise<TransactionsAnalyticsResponseData> {
  try {
    return api
      .post(`${GET_TRANSACTIONS}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: { connectorId, startDate, endDate },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get transactions analytics failed: ${error.message}` })
    throw error
  }
}

export async function getAllPublicConnectors(token: string): Promise<TemplatesType[]> {
  try {
    return api
      .get(GET_ALL_PUBLIC_CONNECTORS, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get all public connectors failed: ${error.message}` })
    throw error
  }
}

export async function getPublicConnectorById(id: string, token: string): Promise<PublicConnectorDetails[]> {
  try {
    console.log(`Getting public connector with ID: ${id}`);
    // Always use the _id endpoint
    const endpoint = `${GET_ALL_PUBLIC_CONNECTORS}/${id}`;
    
    const response = await api
      .get(endpoint, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        timeout: 30000, // 30 seconds timeout
        retry: {
          limit: 2,
          methods: ['GET'],
          statusCodes: [408, 429, 500, 502, 503, 504],
        },
      });
    
    const data = await response.json();
    console.log('Public connector response:', data ? 'Data received' : 'No data');
    
    // Verify response structure
    if (!data) {
      console.error('No data returned for getPublicConnectorById');
      throw new Error('Invalid response from server');
    }
    
    // Type guard to check if data has _id property
    const hasId = (obj: any): obj is { _id: string } => {
      return obj && typeof obj === 'object' && '_id' in obj;
    };
    
    // Handle both array and single object responses
    const isArray = Array.isArray(data);
    if (isArray && data.length === 0) {
      console.error('Empty array returned for getPublicConnectorById');
      throw new Error('Connector not found');
    }
    
    if (!isArray && !hasId(data)) {
      console.error('Invalid response structure (missing _id) for getPublicConnectorById:', data);
      throw new Error('Invalid response format from server');
    }
    
    const connectorData = isArray ? data[0] : data;
    
    // Log media data type for debugging
    if (connectorData?.media?.data) {
      console.log('Media data type:', typeof connectorData.media.data);
      console.log('Is array?', Array.isArray(connectorData.media.data));
      console.log('Data sample:', typeof connectorData.media.data === 'string' && connectorData.media.data.length > 100 
                 ? `${connectorData.media.data.substring(0, 100)}...` 
                 : 'Too large to display');
    } else {
      console.log('No media data in response');
    }
    
    return connectorData;
  } catch (error) {
    console.error('Error in getPublicConnectorById:', error);
    if (error instanceof Error) {
      notification.error({ 
        placement: "topRight", 
        message: "Get public connector failed", 
        description: error.message 
      });
    }
    throw error;
  }
}

export async function createPublicConnectors(
  body: PublicConnectorDetails,
  token: string
): Promise<PublicConnectorDetails[]> {
  try {
    // Create FormData object for file upload
    const formData = new FormData();
    
    console.log('Creating FormData for template creation');
    
    // Append all other fields first
    Object.entries(body).forEach(([key, value]) => {
      if (key !== 'media') {
        if (key === 'methods' && Array.isArray(value)) {
          console.log(`Appending methods as JSON string, count: ${value.length}`);
          formData.append('methods', JSON.stringify(value));
        } else if (value !== undefined && value !== null) {
          console.log(`Appending field: ${key}`);
          formData.append(key, value as any);
        }
      }
    });
    
    // Handle media last to avoid issues with multipart form data
    if (body.media instanceof File) {
      console.log('Media is a File object, appending directly', {
        name: body.media.name,
        type: body.media.type,
        size: body.media.size
      });
      
      // Check file size before uploading
      if (body.media.size > 10 * 1024 * 1024) {
        throw new Error('File too large. Maximum size is 10MB.');
      }
      
      // Check file type
      if (!body.media.type.startsWith('image/') && 
          body.media.type !== 'application/pdf' && 
          body.media.type !== 'application/json') {
        throw new Error('Unsupported file type. Only images, PDFs, and JSON files are allowed.');
      }
      
      // Create a new File with a smaller chunk size to avoid upload issues
      const smallerChunks = new File([body.media], body.media.name, { 
        type: body.media.type,
        lastModified: body.media.lastModified
      });
      
      formData.append('media', smallerChunks);
    } else if (body.media) {
      console.log('Media is not a File object but exists, type:', typeof body.media);
      // If it's a buffer or base64 data, we need to convert it to a file
      if (body.media.data) {
        console.log('Media has data property, creating Blob');
        try {
          // Convert base64 to blob
          const byteCharacters = atob(body.media.data);
          const byteArrays = [];
          for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
          }
          const byteArray = new Uint8Array(byteArrays);
          const blob = new Blob([byteArray], { type: 'image/png' });
          
          // Check blob size
          if (blob.size > 10 * 1024 * 1024) {
            throw new Error('File too large. Maximum size is 10MB.');
          }
          
          const file = new File([blob], 'icon.png', { type: 'image/png' });
          console.log('Created File from data', { size: file.size, type: file.type });
          formData.append('media', file);
        } catch (e) {
          console.error('Error converting media data to File:', e);
          throw new Error('Error processing image data: ' + (e instanceof Error ? e.message : String(e)));
        }
      }
    } else {
      console.log('No media to append');
    }
    
    // Log all form data entries for debugging
    console.log('FormData entries:');
    for (const pair of (formData as any).entries()) {
      console.log(`${pair[0]}: ${typeof pair[1] === 'object' ? 'File or Object' : pair[1]}`);
    }
    
    // Use direct ky call instead of api to avoid the Content-Type header being set
    const response = await ky.post(`${BASE_URL}${CREATE_PUBLIC_CONNECTOR}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        // Don't set Content-Type, let the browser set it for FormData
      },
      body: formData,
      timeout: 120000, // Increase timeout to 120 seconds for large files
      retry: {
        limit: 3,
        methods: ['POST'],
        statusCodes: [408, 413, 429, 500, 502, 503, 504],
        maxRetryAfter: 30000, // Maximum retry after time in milliseconds
      },
      hooks: {
        beforeRequest: [
          request => {
            console.log('Sending request to:', request.url);
          }
        ],
        afterResponse: [
          (request, options, response) => {
            console.log('Response status:', response.status);
            return response;
          }
        ]
      },
      throwHttpErrors: false // Don't throw HTTP errors automatically
    });
    
    return response.json();
  } catch (error) {
    console.error('Error in createPublicConnectors:', error);
    
    // Check if the error has a response property (HTTP error)
    if (error && typeof error === 'object' && 'response' in error && error.response) {
      try {
        // Try to parse the error response as JSON
        const errorData = await (error.response as Response).json();
        console.error('Server error response:', errorData);
        
        // Use the server's error message if available
        if (errorData && errorData.message) {
          notification.error({ 
            placement: "topRight", 
            message: "Create template failed", 
            description: errorData.message,
            duration: 8
          });
          throw new Error(errorData.message);
        }
      } catch (jsonError) {
        // If we can't parse the JSON, fall back to the status text
        console.error('Error parsing error response:', jsonError);
      }
    }
    
    if (error instanceof Error) {
      let errorMessage = `Create public connector failed: ${error.message}`;
      
      // Handle specific error types
      if (error.message.includes('Multipart: Unexpected end of form')) {
        errorMessage = 'File upload was interrupted. Please try again with a smaller file or a more stable connection.';
      } else if (error.message.includes('File too large')) {
        errorMessage = 'File is too large. Maximum size is 10MB.';
      } else if (error.message.includes('Unsupported file type')) {
        errorMessage = 'Unsupported file type. Only images, PDFs, and JSON files are allowed.';
      } else if (error.message.includes('timeout')) {
        errorMessage = 'Request timed out. Please try again with a smaller file or a more stable connection.';
      } else if (error.message.includes('NetworkError')) {
        errorMessage = 'Network error occurred. Please check your internet connection and try again.';
      }
      
      notification.error({ 
        placement: "topRight", 
        message: "Create template failed", 
        description: errorMessage,
        duration: 8
      });
    }
    
    throw error;
  }
}

export async function updatePublicConnector(id: string, body: any, token: string): Promise<DataConnectorsType & { templateId?: string }> {
  try {
    console.log(`Updating public connector with ID: ${id}`);
    console.log('Update body:', body);
    
    // Always use the _id endpoint
    const endpoint = `${UPDATE_PUBLIC_CONNECTOR}${id}`;
    
    // Create FormData object for file upload
    const formData = new FormData();
    
    console.log('Creating FormData for template update');
    
    // Append all other fields first
    Object.entries(body).forEach(([key, value]) => {
      if (key !== 'media') {
        if (key === 'methods' && Array.isArray(value)) {
          console.log(`Appending methods as JSON string, count: ${value.length}`);
          formData.append('methods', JSON.stringify(value));
        } else {
          console.log(`Appending field: ${key}`);
          formData.append(key, value as any);
        }
      }
    });
    
    // Handle media last to avoid issues with multipart form data
    if (body.media instanceof File) {
      console.log('Media is a File object, appending directly', {
        name: body.media.name,
        type: body.media.type,
        size: body.media.size
      });
      
      // Check file size before uploading
      if (body.media.size > 10 * 1024 * 1024) {
        throw new Error('File too large. Maximum size is 10MB.');
      }
      
      // Check file type
      if (!body.media.type.startsWith('image/') && 
          body.media.type !== 'application/pdf' && 
          body.media.type !== 'application/json') {
        throw new Error('Unsupported file type. Only images, PDFs, and JSON files are allowed.');
      }
      
      // Create a new File with a smaller chunk size to avoid upload issues
      const smallerChunks = new File([body.media], body.media.name, { 
        type: body.media.type,
        lastModified: body.media.lastModified
      });
      
      formData.append('media', smallerChunks);
    } else if (body.media) {
      console.log('Media is not a File object but exists, type:', typeof body.media);
      // If it's a buffer or base64 data, we need to convert it to a file
      if (body.media.data) {
        console.log('Media has data property, creating Blob');
        try {
          // Convert base64 to blob
          const byteCharacters = atob(body.media.data);
          const byteArrays = [];
          for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
          }
          const byteArray = new Uint8Array(byteArrays);
          const blob = new Blob([byteArray], { type: 'image/png' });
          
          // Check blob size
          if (blob.size > 10 * 1024 * 1024) {
            throw new Error('File too large. Maximum size is 10MB.');
          }
          
          const file = new File([blob], 'icon.png', { type: 'image/png' });
          console.log('Created File from data', { size: file.size, type: file.type });
          formData.append('media', file);
        } catch (e) {
          console.error('Error converting media data to File:', e);
          throw new Error('Error processing image data: ' + (e instanceof Error ? e.message : String(e)));
        }
      }
    } else {
      console.log('No media to append');
    }
    
    // Log all form data entries for debugging
    console.log('FormData entries:');
    for (const pair of (formData as any).entries()) {
      console.log(`${pair[0]}: ${typeof pair[1] === 'object' ? 'File or Object' : pair[1]}`);
    }
    
    // Use direct ky call instead of api to avoid the Content-Type header being set
    return ky.put(`${BASE_URL}${endpoint}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        // Don't set Content-Type, let the browser set it for FormData
      },
      body: formData,
      timeout: 120000, // Increase timeout to 120 seconds for large files
      retry: {
        limit: 3,
        methods: ['PUT'],
        statusCodes: [408, 413, 429, 500, 502, 503, 504],
        maxRetryAfter: 30000, // Maximum retry after time in milliseconds
      },
      hooks: {
        beforeRequest: [
          request => {
            console.log('Sending request to:', request.url);
          }
        ],
        afterResponse: [
          (request, options, response) => {
            console.log('Response status:', response.status);
            return response;
          }
        ]
      },
      throwHttpErrors: false // Don't throw HTTP errors automatically
    }).json();
  } catch (error) {
    console.error('Error in updatePublicConnector:', error);
    
    // Check if the error has a response property (HTTP error)
    if (error && typeof error === 'object' && 'response' in error && error.response) {
      try {
        // Try to parse the error response as JSON
        const errorData = await (error.response as Response).json();
        console.error('Server error response:', errorData);
        
        // Use the server's error message if available
        if (errorData && errorData.message) {
          notification.error({ 
            placement: "topRight", 
            message: "Update template failed", 
            description: errorData.message,
            duration: 8
          });
          throw new Error(errorData.message);
        }
      } catch (jsonError) {
        // If we can't parse the JSON, fall back to the status text
        console.error('Error parsing error response:', jsonError);
      }
    }
    
    if (error instanceof Error) {
      let errorMessage = `Update public connector failed: ${error.message}`;
      
      // Handle specific error types
      if (error.message.includes('Multipart: Unexpected end of form')) {
        errorMessage = 'File upload was interrupted. Please try again with a smaller file or a more stable connection.';
      } else if (error.message.includes('File too large')) {
        errorMessage = 'File is too large. Maximum size is 10MB.';
      } else if (error.message.includes('Unsupported file type')) {
        errorMessage = 'Unsupported file type. Only images, PDFs, and JSON files are allowed.';
      } else if (error.message.includes('timeout')) {
        errorMessage = 'Request timed out. Please try again with a smaller file or a more stable connection.';
      } else if (error.message.includes('NetworkError')) {
        errorMessage = 'Network error occurred. Please check your internet connection and try again.';
      }
      
      notification.error({ 
        placement: "topRight", 
        message: "Update template failed", 
        description: errorMessage,
        duration: 8
      });
    }
    
    throw error;
  }
}
