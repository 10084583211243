/**
 * Standardized port configuration for all services.
 * This ensures consistency across the application without needing to set environment variables.
 */

export const SERVICE_PORTS = {
  API: 3001,
  AUTH: 3002,
  USERS: 3003,
  CONFIGS: 3004,
  CONNECTORS: 3007,
  NOTIFICATION: 3006,
  ENCRYPTION: 3008,
  WEBSITE: 4000
} as const;

export const SERVICE_PATHS = {
  API: '/api',
  AUTH: '/api-auth',
  USERS: '/api-users',
  CONFIGS: '/api-configs',
  CONNECTORS: '/api-connectors',
  NOTIFICATION: '/api-notification',
  ENCRYPTION: '/api-encryption'
} as const;

/**
 * Get the URL for a service based on environment
 * @param service The service name from SERVICE_PORTS
 * @param isDev Whether the environment is development
 * @returns The complete URL to the service
 */
export function getServiceUrl(serviceName: keyof typeof SERVICE_PORTS, isDev: boolean = process.env.NODE_ENV === 'development'): string {
  const port = SERVICE_PORTS[serviceName];
  return isDev ? `http://localhost:${port}` : `http://${serviceName.toLowerCase()}-service:${port}`;
}

export function getServicePath(serviceName: keyof typeof SERVICE_PATHS): string {
  return SERVICE_PATHS[serviceName];
}

// Helper to get full service URL with path
export function getFullServiceUrl(serviceName: keyof typeof SERVICE_PORTS & keyof typeof SERVICE_PATHS, isDev: boolean = process.env.NODE_ENV === 'development'): string {
  return `${getServiceUrl(serviceName, isDev)}${SERVICE_PATHS[serviceName]}`;
} 