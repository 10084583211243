import {checkAuth, getAllConnectors} from "@/shared/api";
import {useAuthStore} from "@/store/auth";
import {useConnectorsStore} from "@/store/connectors";
import {DataConnectorsType} from "@/types/DataConnectorsType";
import {getAuthToken} from "@/utils/auth";
import {Layout, Row, Spin} from "antd";
import {FC, useEffect, useLayoutEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {useTernaryDarkMode} from "usehooks-ts";

const DashboardLayout: FC = () => {
	const { loggedIn, set } = useAuthStore(store => ({
		loggedIn: store.loggedIn,
		set: store.set,
	}))
	const setConnectors = useConnectorsStore(state => state.setConnectors)
	const { isDarkMode } = useTernaryDarkMode({ localStorageKey: "theme" })
	const [loading, setLoading] = useState(true);

	const resetUserState = () => {
		set(store => {
			store.loggedIn = false
			store.user = undefined
		})
	}

	useEffect(() => {
		const getMe = async () => {
			const token = getAuthToken()

			if (!token) {
				console.warn("No auth token found. Resetting user state.")
				resetUserState()
				setLoading(false)
				return
			}

			try {
				// Get auth data first
				const userData = await checkAuth(token)
				
				set(store => {
					store.loggedIn = !!userData
					store.user = userData
				})
				
				// Then get connectors separately - if this fails, we still have user data
				try {
					const connectorsResponse = await getAllConnectors(token)
					setConnectors(connectorsResponse || [])
				} catch (connectorError) {
					// Just log connector errors but don't reset user state
					console.error("Error fetching connectors:", connectorError)
					// Still set empty connectors to prevent UI issues
					setConnectors([])
				}
			} catch (authError) {
				console.error("Error during authentication check:", authError)
				resetUserState()
			} finally {
				setLoading(false)
			}
		}

		getMe()
	}, []);

	if (loading) return (
		<Row 
			justify="center" 
			align="middle" 
			style={{
				height: '100vh',
				width: '100%'
			}}
		>
			<Spin size="large"/>
		</Row>
	);

	return (
		<Layout className={isDarkMode ? "dark" : "light"}>
			<Outlet/>
		</Layout>
	)
}

export default DashboardLayout