import App from "@/components/app"
import { StrictMode } from "react"
import ReactDOM from "react-dom"

const rootElement = document.querySelector("#root") as HTMLElement

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  rootElement
)
