import { routes } from "@/pages"
import { FC, memo, Suspense, useEffect, useRef } from "react"
import { HelmetProvider } from "react-helmet-async"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "./index.less"

import { checkAuth, getAllConnectors } from "@/shared/api"
import { useAuthStore } from "@/store/auth"
import { useConnectorsStore } from "@/store/connectors"
import { getAuthToken } from "@/utils/auth"
import { DataConnectorsType } from "@/types/DataConnectorsType"

const App: FC = () => {
  const { loggedIn } = useAuthStore(store => ({
    loggedIn: store.loggedIn,
  }))

  const router = createBrowserRouter(routes(!!loggedIn))

	return (
    <HelmetProvider>
      <Suspense fallback={<div className="loading-spinner">Loading...</div>}>
        <RouterProvider router={router} />
      </Suspense>
    </HelmetProvider>
  )
}

export default memo(App)
export { App }
