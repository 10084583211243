import DashboardLayout from "@/components/layout/DashboardLayout";
import { FC, lazy } from "react"
import { Navigate, Outlet, RouteObject } from "react-router-dom"
import DefaultLayout from "../components/layout/DefaultLayout"

const SignIn = lazy<FC>(() => import("./signin"))
const SignUp = lazy<FC>(() => import("./signup"))
const SignupAdditionalDetails = lazy<FC>(() => import("./signupAdditionalDetails"))
const DetailsPage = lazy<FC>(() => import("./detailsPage"))
const ForgotPassword = lazy<FC>(() => import("./forgotPassword"))
const ResetPassword = lazy<FC>(() => import("./resetPassword"))
const ConnectorLibrary = lazy<FC>(() => import("./connectorLibrary"))
const ConfigurePublicConnector = lazy<FC>(() => import("./configurePublicConnector"))
const ConnectorPage = lazy<FC>(() => import("./connectorPage"))
const PublicConnectorGenerate = lazy<FC>(() => import("./publicConnectorGenerate"))
const PublicSourceConfiguration = lazy<FC>(() => import("./publicSourceConfiguration"))
const PublicDestinationConfiguration = lazy<FC>(() => import("./publicDestinationConfiguration"))
const PublicDataMapping = lazy<FC>(() => import("./publicDataMapping"))
const PublicIntegrationProfile = lazy<FC>(() => import("./publicIntegrationProfile"))
const PublicConfigsCards = lazy<FC>(() => import("./publicConfigsCards"))
const StartIntegration = lazy<FC>(() => import("./startIntegration"))
const EditSourceConfiguration = lazy<FC>(() => import("./editSourceConfiguration"))
const EditDestinationConfiguration = lazy<FC>(() => import("./editDestinationConfiguration"))
const EditIntegration = lazy<FC>(() => import("./editIntegration"))
const EditDataMapping = lazy<FC>(() => import("./editDataMapping"))
const EditIntegrationProfile = lazy<FC>(() => import("./editIntegrationProfile"))
const EditConfigurePublicConnector = lazy<FC>(() => import("./editConfigurePublicConnector"))
const EditPublicConnector = lazy<FC>(() => import("./editPublicConnector"))
const SourceConfiguration = lazy<FC>(() => import("./sourceConfiguration"))
const DestinationConfiguration = lazy<FC>(() => import("./destinationConfiguration"))
const DataMapping = lazy<FC>(() => import("./dataMapping"))
const Dashboard = lazy<FC>(() => import("./dashboard"))
const IntegrationSetup = lazy<FC>(() => import("./integrationSetup"))
const IntegrationEngine = lazy<FC>(() => import("./integrationEngine"))
const IntegrationProfile = lazy<FC>(() => import("./integrationProfile"))
const YourMarketplace = lazy<FC>(() => import("./yourConnectors"))
const FAQ = lazy<FC>(() => import("./faq"))
const HelpCenter = lazy<FC>(() => import("./helpCenter"))
const PrivacyPolicy = lazy<FC>(() => import("./privacyPolicy"))

const authRoutes: RouteObject[] = [
  {
    path: "*",
    element: <Navigate to='/' replace />,
  },
  {
    path: "",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to='/sign-in' replace />,
      },
      {
        path: "/sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "sign-up/additional-details",
        element: <SignupAdditionalDetails />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
    ],
  },
]

const menuRoutes: RouteObject[] = [
  {
    path: "*",
    element: <Navigate to='/' replace />,
  },
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to='/dashboard' replace />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/details/:id",
        element: <DetailsPage />,
      },
      {
        path: "generate-public-connector/:fieldType",
        element: <PublicConnectorGenerate />,
      },
      {
        path: "public/source-configuration",
        element: <PublicSourceConfiguration />,
      },
      {
        path: "public/destination-configuration",
        element: <PublicDestinationConfiguration />,
      },
      {
        path: "public/data-mapping",
        element: <PublicDataMapping />,
      },
      {
        path: "public/integration-profile",
        element: <PublicIntegrationProfile />,
      },
      {
        path: "public/public-integration",
        element: <PublicConfigsCards />,
      },
      {
        path: "configure-public-connector/:fieldType/:id",
        element: <ConfigurePublicConnector />,
      },
      {
        path: "edit-public-connector-config/:fieldType/:id",
        element: <EditConfigurePublicConnector />,
      },
      {
        path: "edit-public-connector/:id",
        element: <EditPublicConnector />,
      },
      {
        path: "integration-setup",
        element: <IntegrationSetup />,
      },
      {
        path: "integration-engine",
        element: <IntegrationEngine />,
      },
      {
        path: "connector-library",
        element: <ConnectorLibrary />,
      },
      {
        path: "connector-library/:id",
        element: <ConnectorPage />,
      },
      {
        path: "start-integration/:id",
        element: <StartIntegration />,
      },
      {
        path: "edit-integration/:id",
        element: <EditIntegration />,
      },
      {
        path: "edit-integration/data-mapping/:id",
        element: <EditDataMapping />,
      },
      {
        path: "edit-integration/integration-profile/:id",
        element: <EditIntegrationProfile />,
      },
      {
        path: "edit-integration/source-configuration/:id",
        element: <EditSourceConfiguration />,
      },
      {
        path: "edit-integration/destination-configuration/:id",
        element: <EditDestinationConfiguration />,
      },
      {
        path: "start-integration/:id/source-configuration",
        element: <SourceConfiguration />,
      },
      {
        path: "start-integration/:id/destination-configuration",
        element: <DestinationConfiguration />,
      },
      {
        path: "start-integration/:id/data-mapping",
        element: <DataMapping />,
      },
      {
        path: "start-integration/integration-profile",
        element: <IntegrationProfile />,
      },
      {
        path: "your-marketplace",
        element: <YourMarketplace />,
      },
      {
        path: "faq",
        element: <FAQ />,
      },
      {
        path: "help-center",
        element: <HelpCenter />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
    ],
  },
]

const undefinedAuthorization: RouteObject[] = [
  {
    path: "*",
    element: <Outlet />,
  },
]

export const routes = (authorized: boolean | undefined): RouteObject[] => {
  if (authorized === undefined) {
    return undefinedAuthorization
  }
  return authorized ? menuRoutes : authRoutes
}
